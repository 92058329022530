import { EventRegister } from 'utils/EventRegister.js';

let user = null;

export const setUser = (data) => {
    user = data;
    EventRegister.emit('userChanged', user)
};

export const getUser = () => {
    return user;
};
