import React, { useState } from 'react';

import Steps from 'components/steps/Steps.js';
import News from 'components/news/News.js';
import Videos from 'components/videos/Videos.js';
// import IdeaList from 'components/idealist/IdeaList.js';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function MainPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        ideaList: (page && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'idea-list')) && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'idea-list').contentList,
        stepList: (page && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'kk-folyamat')) && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'kk-folyamat').contentList,
        videoList: (page && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'kk-video')) && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'kk-video').contentList,
        newsList: (page && getValueFromParameterMap(page, 'newsList')) && getValueFromParameterMap(page, 'newsList'),
    }

    return (
        <NBossCMSPage className='flex flex-col items-center justify-center' pageprops={props} pageDataReady={page => { setPage(page) }}>

            {page && < div className='w-full max-w-tv-boxed flex flex-col gap-8'>

                {/* {_data.ideaList && <IdeaList data={_data.ideaList} />} */}

                {_data.stepList && <Steps data={_data.stepList} />}

                {_data.newsList && <News data={_data.newsList} title={'Hírek, aktualitások és közelgő események'} subTitle={'minden, amit a közösségi költségvetéssel kapcsolatban tudni kell'} year={2023}/>}

                {_data.videoList && <Videos data={_data.videoList} />}

            </div>}

        </NBossCMSPage >
    );
}