import React, { useState, useEffect } from 'react';

import YoutubeEmbed from 'elements/YoutubeEmbed';
import YoutubeImage from 'elements/YoutubeImage';

import DynamicIcon from 'elements/DynamicIcon';

import globalConfig from 'config/globalConfig.js'

export default function Videos(props) {
    const [currentVideo, setCurrentVideo] = useState(null);

    useEffect(() => {
        setCurrentVideo(props.data[0]);
    }, [props.data]);


    const _data = {
        title: currentVideo && currentVideo.parameterMap['title'].value,
        content: currentVideo && currentVideo.parameterMap['description'].value,
        videoId: currentVideo && currentVideo.parameterMap['media-url'].value,
    }

    return (
        <div className={'relative p-0 sm:p-10 flex flex-col gap-16' + (props.className || '')}>

            <div className='flex flex-col lg:flex-row items-start bg-tv-cyan dark:bg-tv-yellow bg-opacity-95 rounded-xl shadow-xl p-6 gap-8'>

                <Heading className='flex lg:hidden w-full' label='Kövesse YouTube csatornánkat' />

                <div className='w-full lg:w-1/3 flex flex-col gap-8 self-stretch order-2 lg:order-1'>

                    <Heading className='hidden lg:flex' label='Kövesse önkormányzatunk YouTube csatornáját' />

                    <div className='lg:flex-1 w-full shadow-inner bg-black bg-opacity-10 rounded-lg overflow-hidden '>

                        <div className='relative w-full lg:h-full lg:overflow-y-scroll videobar'>
                            <div className='lg:absolute w-full grid grid-cols-2 gap-4 p-4 nobar'>
                                {props.data.map((item, index) => <VideoThumbnailItem key={index} data={item} index={index} onClick={() => setCurrentVideo(item)} />)}
                            </div>
                        </div>

                    </div>

                </div>

                <div className='flex-1 flex flex-col rounded-lg shadow-xl bg-white bg-opacity-10  order-1 lg:order-2'>

                    <div className='aspect-video rounded-lg shadow-xl bg-white bg-opacity-10 overflow-hidden'>
                        {_data.videoId && <YoutubeEmbed embedId={_data.videoId} />}
                    </div>

                    <div className='flex flex-col gap-1 px-6 py-3'>
                        {_data.title && < div className='uppercase text-xl text-tv-yellow dark:text-black font-bold'>{_data.title}</div>}
                        {_data.content && <div className='text-white dark:text-black text-sm dark:text-base' dangerouslySetInnerHTML={{ __html: _data.content }} />}
                    </div>

                </div>

            </div>

            <img className='absolute hidden md:block left-0 lg:left-0 top-0 lg:-top-0 w-16 lg:w-18 h-16 lg:h-18 z-40 dark:hidden' alt='artifact' src='assets/images/icons/artifact-5.svg' />


        </div >
    );
}

function Heading(props) {
    return (
        <div className={'flex flex-col gap-1 bg-tv-purple ml-0 lg:-ml-12 shadow-xl rounded-lg lg:rounded-xl p-6 ' + (props.className || '')}>

            <div className='flex flex-row gap-3 items-center'>
                <div className='font-extrabold text-tv-yellow dark:text-white uppercase text-4xl lg:text-5xl'>Videóink</div>
                <YouTubeButton className='block lg:hidden text-4xl text-white' />
            </div>

            <div className='flex flex-row gap-2 items-center'>
                <div className='text-white text-sm'>{props.label}</div>
                <YouTubeButton className='hidden lg:block text-lg text-white hover:text-tv-red mb-0.5' />
            </div>

        </div>
    );
}

function YouTubeButton(props) {
    return (
        <a className={'transition  ' + (props.className || '')} href={globalConfig.etc.youtubeUrl} target='_blank' rel='noreferrer'>
            <DynamicIcon className='' iconName='FaYoutube' />
        </a>
    );
}

function VideoThumbnailItem(props) {
    return (
        <button className='group relative aspect-video rounded-lg shadow-xl bg-white bg-opacity-20 overflow-hidden' onClick={() => props.onClick()}>

            <YoutubeImage className='absolute top-0 left-0 w-full h-full' embedId={props.data.parameterMap['media-url'].value} />

            <div className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-tv-yellow bg-opacity-20 transition opacity-0 group-hover:opacity-100'>

                {/* <div className='text-xs text-white font-semibold'>{props.data.parameterMap['title'].value}</div> */}

                <div className='w-12 h-12 flex items-center justify-center rounded-full bg-tv-purple shadow-lg transition scale-0 group-hover:scale-100'>
                    <DynamicIcon className='text-white ml-[2px]' iconName='FaPlay' />
                </div>

            </div>

        </button>
    );
}
