import React from 'react';
import { Link } from 'react-router-dom';

import DynamicIcon from 'elements/DynamicIcon.js';
import DarkModeSwitch from 'elements/DarkModeSwitch.js';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Footer(props) {
    const getContactData = () => {
        let dataList = [
            { 'iconName': 'FaMapMarkerAlt', 'labelKey': 'address', 'valueKey': 'address-link' },
            { 'iconName': 'FaEnvelope', 'labelKey': 'mailing-address', 'valueKey': 'address-link' },
            { 'iconName': 'FaPhone', 'labelKey': 'phone-number', 'valuePrefix': 'tel:' },
            { 'iconName': 'FaAt', 'labelKey': 'email', 'valuePrefix': 'mailto:' }
        ];

        const contactConfig = getValueFromParameterMap(getWebsite().globalContent, 'contacts');

        for (let key in dataList) {
            dataList[key]['label'] = getValueFromParameterMap(contactConfig, dataList[key]['labelKey']);
            if (dataList[key]['valueKey'])
                dataList[key]['value'] = getValueFromParameterMap(contactConfig, dataList[key]['valueKey']);
            if (dataList[key]['valuePrefix'])
                dataList[key]['value'] = dataList[key]['valuePrefix'] + dataList[key]['label'];
        }

        return dataList;
    }

    return (
        <footer className={'relative w-full flex flex-col items-end justify-end ' + (props.className || '')}>

            <div className='sm:absolute w-full min-h-full top-0 left-0 flex justify-center site-px'>

                <div className='w-full max-w-tv-boxed flex flex-col gap-8 items-start mt-0 2xl:mt-8'>

                    <div className='w-full md:w-auto flex flex-col lg:flex-row gap-8 lg:gap-16 items-start bg-blue-4000 bg-opacity-60'>

                        <BoxList className='w-full md:w-auto bg-red-4000 bg-opacity-60' contact={getContactData()} references={getValueFromParameterMap(getWebsite().footerContent, 'references')} />

                        <LogoList className='' />

                    </div>

                    <Copyright className='hidden lg:flex' />

                </div>

            </div>

            <img className='w-full max-w-[960px] sm:mt-32 xl:mt-0 dark:opacity-0' alt='logo' src='assets/images/footer-image.png' />

            <Copyright className='block lg:hidden' />

            <img className='absolute hidden md:block right-52 xl:right-72 top-[4.5rem] xl:-top-8 w-14 h-14 z-40 dark:hidden' alt='artifact' src='assets/images/icons/artifact-4.svg' />

        </footer>
    );
}

function Copyright(props) {
    return (
        <div className={'w-full lg:w-auto bg-tv-purple sm:bg-tv-red dark:bg-tv-yellow rounded-none lg:rounded-xl text-white dark:text-black text-center lg:text-left px-4 py-2 text-xs dark:text-sm shadow-xl flex flex-col sm:flex-row items-center justify-center sm:divide-x divide-white dark:divide-black divide-opacity-50 sm:leading-none gap-2 sm:gap-0 ' + (props.className || '')}>
            <div className='mr-2'>2024 © Terézváros Polgármesteri Hivatal - Minden jog fenntartva.</div>
            <DarkModeSwitch label className='pl-2' />
        </div>
    );
}

function LogoList(props) {
    return (
        <div className={'flex flex-row gap-8 items-center ' + (props.className || '')}>
            <a href='https://terezvaros.hu/' className='transition hover:opacity-70'><img className='h-24' alt='logo' src='assets/images/logo/shield-text-top-fill-white.svg' /></a>
            <a href='https://transparency.hu/' className='transition hover:opacity-70'><img className='h-16' alt='logo' src='assets/images/ti-logo-white.svg' /></a>
        </div>
    );
}

function BoxList(props) {
    return (
        <div className={'flex flex-col sm:flex-row gap-5 ' + (props.className || '')}>
            <List className='bg-tv-purple dark:bg-tv-yellow flex-1 md:flex-none' title='Kapcsolat'>
                {props.contact.map((item, index) => <ListItem key={index} label={item.label} iconName={item.iconName} href={item.value} />)}
            </List>
            <List className='bg-tv-cyan dark:bg-tv-yellow flex-1 md:flex-none' title='Hivatkozások'>
                {(props.references && props.references.contentList.length > 0) && props.references.contentList.map((item, index) => (<ListItem key={index} label={getValueFromParameterMap(item, 'title')} href={getValueFromParameterMap(item, 'url-out') ? getValueFromParameterMap(item, 'url-out') : getValueFromParameterMap(item, 'url')} disc />))}
            </List>
        </div>
    );
}

function List(props) {
    return (
        <div className={'p-5 rounded-xl flex flex-col gap-2 shadow-xl ' + (props.className || '')}>
            <div className='text-tv-yellow dark:text-black uppercase font-bold text-sm dark:text-base '>{props.title}</div>
            <div className='flex flex-col gap-1'>
                {props.children}
            </div>
        </div>
    );
}

function ListItem(props) {
    let label = props.label;

    if (props.href)
        label = <a href={props.href}>{props.label}</a>;
    else if (props.to)
        label = <Link to={props.to}>{props.label}</Link>;
    else
        label = <div className='text-left'>{props.label}</div>;

    return (
        <div className={'text-white dark:text-black text-xs dark:text-sm flex flex-row items-center transition ' + (props.href || props.to ? 'hover:text-tv-yellow group ' : '') + (props.className || '')}>
            {(props.iconName || props.disc) ? <DynamicIcon className={'mr-2 transition group-hover:text-tv-yellow ' + (props.disc ? 'text-xxs' : 'text-xs')} iconName={props.iconName ? props.iconName : 'FaChevronRight'} /> : null}
            {label}
        </div>
    );
}