import React from 'react';

// import DynamicIcon from 'elements/DynamicIcon';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getClassNameByColor, getColorByIndex } from 'utils/DesignUtils.js';

import globalConfig from 'config/globalConfig.js'

export default function News(props) {
    const _data = {
        newsList: props.data.contentList
    }

    return (
        <div className={'py-0 sm:py-16 flex flex-col gap-0' + (props.className || '')}>

            <div className='relative flex flex-col items-center justify-center gap-1 bg-tv-red dark:bg-tv-yellow shadow-xl rounded-xl p-6 bg-no-repeat bg-cover bg-center dark:bg-[length:0px_0px]' style={{ backgroundImage: 'url(assets/images/pattern-red.png)' }}>

                <div className='font-extrabold text-white dark:text-black uppercasee text-2xl lg:text-5xl text-center tracking-wide'>{props.title}</div>
                <div className='text-tv-yellow dark:text-black text-sm lg:text-lg dark:lg:text-xl lg:font-bold text-center'>{props.subTitle}</div>

                {/* <img className='absolute hidden md:block right-2 2xl:right-2 -top-[64px] 2xl:-top-[64px] w-20 h-20 z-40' alt='artifact' src='assets/images/icons/artifact-3.svg' /> */}
                <img className='absolute hidden md:block right-2 2xl:right-2 -top-[64px] 2xl:-top-[64px] w-20 h-20 z-40 dark:hidden' alt='artifact' src='assets/images/icons/artifact-3.svg' />

            </div>

            <div className='rounded-b-xl shadow-xl bg-white bg-opacity-10 p-6 mx-0 md:mx-10'>

                <div className='columns-1 md:columns-2 lg:columns-3 gap-6 [column-fill:_balance] box-border mx-auto before:box-inherit after:box-inherit'>
                    {_data.newsList && _data.newsList.map((item, index) => (props.year == new Date(item.publicCreationDate).getFullYear() && <NewsItem key={index} data={item} index={index} />))}
                </div>

            </div>

        </div>
    );
}

function NewsItem(props) {
    let _data = {
        creationDate: props.data && props.data.publicCreationDate,
        author: (props.data && props.data.publicAuthor) && props.data.publicAuthor.name,
        photographer: getValueFromParameterMap(props.data, 'photographer'),
        tagList: getValueFromParameterMap(props.data, 'tagList'),
        image: (getValueFromParameterMap(props.data, 'coverImage') && !props.disableImage) && getValueFromParameterMap(props.data, 'coverImage').downloadUrl,
        title: getValueFromParameterMap(props.data, 'heading'),
        subTitle: getValueFromParameterMap(props.data, 'subHeading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: globalConfig.etc.baseUrl + getValueFromParameterMap(props.data, 'url'),
    }

    const getBgColor = () => { return getClassNameByColor(getColorByIndex(props.index), 'bg') };

    return (
        <div className={'flex flex-col gap-4 break-inside-avoid mb-6 shadow-xl rounded-xl overflow-hidden p-4 dark:bg-tv-yellow ' + getBgColor() + ' ' + (props.className || '')}>

            <div className='rounded-lg bg-white bg-opacity-10 overflow-hidden'>

                <a href={_data.url} className={'rounded-lg shadow-xl relative w-full flex items-end overflow-hidden transition-all group'} >
                    {_data.image && <svg className='w-full invisible' viewBox='0 0 16 9' />}
                    <div className='absolute w-full h-full bg-cover bg-center transition-all filter group-hover:grayscale group-hover:blur-xxs' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
                </a>

                <div className='flex flex-col gap-1 p-4'>

                    <div className={'font-bold text-tv-yellow dark:text-black dark:text-lg uppercase'}>{_data.title}</div>
                    <div className='text-white dark:text-black text-sm dark:text-base mb-0' dangerouslySetInnerHTML={{ __html: _data.lead }} />

                </div>

            </div>

            <a href={_data.url} className='w-full group transition flex items-center justify-center bg-black bg-opacity-10 dark:bg-opacity-100 hover:bg-opacity-20 rounded-lg shadow-xl py-4'>
                <div className='text-white group-hover:text-tv-yellow uppercase font-bold text-xs dark:text-sm tracking-wide group-hover:scale-110 transition'>Tovább olvasom</div>
            </a>


        </div>
    );
}

// function NewsListerItemMeta(props) {
//     return (
//         <div className={'text-white text-xs font-bold flex flex-row items-center truncate ' + (props.className || '')}><DynamicIcon className={'mr-1.5'} iconName={props.icon} />{props.value}</div>
//     )
// }