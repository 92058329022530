import nBossConnection from 'services/nBossConnection.js';

class VisitorAccessManagement {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'visitorKkAccessManagement';
    }

    getLoggedInClient() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getLoggedInClient`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    closeVote() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/closeVote`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new VisitorAccessManagement();
