import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// import DictionaryText from 'elements/DictionaryText';

// import globalConfig from 'config/globalConfig.js'

export default function CookieConsent(props) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('tvCommunityBudgetCookieConsent')
        let showPopup = cookieConsent ? false : true;
        if (showPopup)
            setTimeout(() => setVisible(true), 3000);
    }, [])

    return (
        <motion.div
            className={'fixed bottom-0 left-0 w-full md:w-96 bg-tv-purple rounded-t-xl md:rounded-xl shadow-xl md:m-6 p-4 sm:p-6 bg-no-repeat bg-bottom sm:bg-top bg-cover ' + (props.className || '')}
            // style={{ backgroundImage: 'url(assets/images/pattern-red.png)' }}
            initial='hidden'
            animate={visible ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, y: 0, display: 'flex' }, 'hidden': { opacity: 0, y: 300, transitionEnd: { display: 'none' } } }} >

            <div className='text-white flex flex-col gap-2'>

                <div className='font-bold text-xl dark:text-2xl'>Cookie beállítás</div>
                <div className='text-xs dark:text-sm content'>Honlapunk megfelelő működése érdekében Cookie-knak nevezett adatfájlokat (magyarul: sütiket) helyezünk el számítógépén, amelyek javítják a felhasználói élményt és biztosítják az oldal megfelelő működését.</div>

                <a href='https://terezvaros.hu/oldal/adatkezelesi-tajekoztato' className='font-bold text-sm dark:text-base transition-all hover:underline'>További információk</a>

                <div className='mt-1 w-full'>
                    <button
                        className='transition w-full p-3 rounded-lg bg-tv-yellow hover:bg-black text-tv-purple dark:text-black hover:text-white dark:hover:text-white flex flex-row gap-4 items-center justify-center uppercase font-bold text-xs dark:text-sm tracking-widest'
                        onClick={() => { setVisible(false); localStorage.setItem('tvCommunityBudgetCookieConsent', Date.now()); }}>
                        Elfogadom
                    </button>
                </div>

            </div>

        </motion.div>
    );
}