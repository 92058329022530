import React, { useState } from 'react';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import DynamicIcon from 'elements/DynamicIcon.js';

export default function Modal(props) {
    return (
        <ModalBackground onBackgroundClick={() => props.onClose()}>

            <motion.div
                className='w-full flex flex-col max-w-md p-4 max-h-screen overflow-hidden'
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}>

                <div className='flex flex-col flex-1 overflow-hidden bg-tv-purple dark:bg-black shadow-xl rounded-xl'>
                    {props.children}
                </div>

            </motion.div>

        </ModalBackground>
    );
}

export function ModalBackground(props) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-50 w-full h-full'>
            <div className='relative w-full h-full flex items-center justify-center'>
                <div className='absolute top-0 left-0 w-full h-full bg-opacity-30 bg-black backdrop-filter backdrop-grayscale-50 z-0' onClick={() => props.onBackgroundClick()} />
                <div className='z-10'>
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}

export function ModalHeader(props) {
    return (
        <div className='w-full flex flex-row px-4 sm:px-6 py-6 bg-tv-yellow dark:bg-tv-yellow text-tv-purple dark:text-black text-lg sm:text-xl dark:sm:text-2xl font-bold shadow-xl bg-no-repeat bg-center bg-cover rounded-xl dark:bg-[length:0px_0px]' style={{ backgroundImage: 'url(/assets/images/pattern-yellow.png)' }}>
            <div className='flex-1'>{props.children}</div>
            {!props.disableClose && <button onClick={props.onClose ? props.onClose : null} className='p-2 -mr-2 group transition hover:opacity-50'>
                <DynamicIcon className='text-lg dark:text-2xl self-center' iconName={'FaTimes'} />
            </button>}
        </div >
    );
}

export function ModalContent(props) {
    return (
        <div className={'flex-1 overflow-y-auto w-full flex flex-col gap-4 p-4 sm:p-6 ' + (props.className || '')}>
            {props.children}
        </div>
    );
}

export function ModalButton(props) {
    const primaryClassName = 'bg-tv-yellow text-tv-purple dark:text-black hover:bg-tv-red hover:text-white dark:hover:text-white'
    const secondaryClassName = primaryClassName
    const baseClassName = 'flex flex-row gap-2 items-center justify-center disabled:bg-tv-gray-dark transition p-3 uppercase font-bold text-xs dark:text-sm tracking-widest disabled:cursor-not-allowed rounded-lg'

    const className = baseClassName + ' ' + (props.secondary ? secondaryClassName : primaryClassName) + ' ' + (props.className || '')

    if (props.to)
        return (<Link className={className} to={props.to} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></Link>)
    else
        return (<button className={className} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></button>);
}

function ModalButtonContent(props) {
    return (
        <>
            {props.icon && <DynamicIcon className={'text-sm'} iconName={props.icon} />}
            {props.label}
        </>
    );
};
