import nBossConnection from 'services/nBossConnection.js';

class KauControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'kauControl';
    }

    /**
     */
     getLastMessage() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getLastMessage`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new KauControl();
