import React, { useState, useEffect } from 'react';

import { TermsBox, IdeaItem } from 'components/vote/Vote';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import CmsFrontendControl from 'services/CmsFrontendControl.js';

export default function Result(props) {
    const [ideas, setIdeas] = useState(null);


    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        contentText: getValueFromParameterMap(props.data, 'content-text'),
        ideaList: getValueFromParameterMap(props.data, 'idea-list'),
        year: getValueFromParameterMap(props.data, 'year'),
    }

    useEffect(() => {
        loadList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    const loadList = () => {
        CmsFrontendControl.getList(props.data.parameterMap['idea-list'].value.key, 0, 0).then(result => {
            // const list = ideas ? orderByShuffle(ideas, _data.ideaList.contentList) : shuffleArray(_data.ideaList.contentList)

            const _list = [];
            for (let i = 0; i < result.contentList.length; i++) { if (getValueFromParameterMap(result.contentList[i], 'vote') ) _list.push(result.contentList[i]); }

            const _orderList = _list.sort((a, b) => (getValueFromParameterMap(a, 'vote') > getValueFromParameterMap(b, 'vote')) ? 1 : ((getValueFromParameterMap(b, 'vote') > getValueFromParameterMap(a, 'vote')) ? -1 : 0));

        
            
            setIdeas(_orderList.reverse());
        });
    }

    // const orderByPoints = (_list) => {
    //     let order = [];

    //     return _list;
    // }

    // const orderByShuffle = (_old, _new) => {
    //     let order = [];
    //     for (let i = 0; i < _old.length; i++)
    //         order.push(_old[i].key);
    //     let __new = [];
    //     for (let i = 0; i < order.length; i++) {
    //         for (let j = 0; j < _new.length; j++) {
    //             if (order[i] === _new[j].key)
    //                 __new.push(_new[j]);
    //         }
    //     }
    //     return __new;
    // }

    return (
        <div className={'py-0 sm:py-10 flex flex-col items-center justify-center gap-12 ' + (props.className || '')}>

            <TermsBox hideTerms title={_data.title} content={_data.contentText} />

            {console.log(ideas)}

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-0 sm:px-5 gap-12'>
                {ideas && ideas.map((item, index) => (getValueFromParameterMap(item, 'year') == _data.year) && <IdeaItem
                    key={index}
                    data={item}
                    index={index}
                    vote={item.dataMap.vote.voteValue}
                    display={true}
                    voteSum={getValueFromParameterMap(item, 'vote')} />)}
            </div>

        </div>
    );
}