import React, { useState, useEffect } from 'react';
import { EventRegister } from 'utils/EventRegister.js';

import DynamicIcon from 'elements/DynamicIcon.js';
// import DictionaryText from 'elements/DictionaryText';
import Modal, { ModalButton, ModalHeader, ModalContent } from 'elements/modal/Modal';

import VisitorAccessManagement from 'services/VisitorAccessManagement.js';
import VisitorUserLogin from 'services/VisitorUserLogin.js';
import VisitorUserRegistrationControl from 'services/VisitorUserRegistrationControl.js';

// import { getTranslation } from 'utils/DictionaryUtils.js';
import { setUser, getUser } from 'utils/UserUtils.js';

export default function ModalAuth(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [login, setLogin] = useState(true);

    const [userName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState(null);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);

    const [passwordAgain, setPasswordAgain] = useState('');
    const [passwordAgainError, setPasswordAgainError] = useState(null);

    const [fullName, setFullName] = useState('');
    const [fullNameError, setFullNameError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(null);

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(null);

    // const [zipCode, setZipCode] = useState('');
    // const [zipCodeError, setZipCodeError] = useState(null);

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsAcceptedError, setTermsAcceptedError] = useState(false);

    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [feedback, setFeedback] = useState(false);

    const doLogout = () => {
        VisitorUserLogin.logout().then(result => {
            setUser(null);
        });
    };

    const doLogin = () => {
        VisitorUserLogin.login(email, password).then(result => {
            if (result) {
                VisitorAccessManagement.getLoggedInClient().then(result => { setUser(result); });
                //setFeedback(true);
            } else {
                setEmailError(true);
                setPasswordError(true);
            }
        });
    };

    const doRegistration = () => {
        VisitorUserRegistrationControl.save({ userName, email, password, passwordAgain, fullName, phoneNumber, address }).then(result => {
            if (result.successful)
                setFeedback(true);
            else {
                if (result.properties && result.properties.userName) setUserNameError(result.properties.name[0].message);
                if (result.properties && result.properties.email) setEmailError(result.properties.email[0].message);
                if (result.properties && result.properties.password) setPasswordError(result.properties.password[0].message);
                if (result.properties && result.properties.passwordAgain) setPasswordAgainError(result.properties.passwordAgain[0].message);
                if (result.properties && result.properties.fullName) setFullNameError(result.properties.fullName[0].message);
                if (result.properties && result.properties.phoneNumber) setPhoneNumberError(result.properties.phoneNumber[0].message);
                if (result.properties && result.properties.address) { setAddressError(result.properties.address[0].message); }
                // if (result.properties && result.properties.zipCode) setZipCodeError(result.properties.zipCode[0].message);
            }
        });
    };

    useEffect(() => {
        setLoggedIn(getUser() ? true : false)
        let userChangedEvent = EventRegister.addEventListener('userChanged', (data) => { setLoggedIn(data ? true : false); });
        return () => EventRegister.removeEventListener(userChangedEvent)
    }, []);

    useEffect(() => {
        if (feedback) {
            setButtonEnabled(false);
        } else if (login) {
            if (email !== '' && password !== '')
                setButtonEnabled(true);
            else
                setButtonEnabled(false);
        } else {
            if (/*userName !== '' &&*/ email !== '' /*&& password !== '' && passwordAgain !== '' && termsAccepted*/ && address !== '')
                setButtonEnabled(true);
            else
                setButtonEnabled(false);
        }
    }, [email, feedback, login, password, passwordAgain, termsAccepted, userName, address]);

    useEffect(() => {
        if (login)
            setFeedback(false);
    }, [login]);

    useEffect(() => { setUserNameError(null); }, [userName]);
    useEffect(() => { setEmailError(null); }, [email]);
    useEffect(() => { setPasswordError(null); }, [password]);
    useEffect(() => { setPasswordAgainError(null); }, [passwordAgain]);
    useEffect(() => { setFullNameError(null); }, [fullName]);
    useEffect(() => { setPhoneNumberError(null); }, [phoneNumber]);
    useEffect(() => { setAddressError(null); }, [address]);
    useEffect(() => { setTermsAcceptedError(null); }, [termsAccepted]);

    return (
        <Modal {...props}>

            <ModalHeader {...props}>
                {loggedIn ? <div>{'Üdvözöljük, ' + (getUser().fullName ? getUser().fullName : getUser().userName) + '!'}</div> : (login ? 'Bejelentkezés' : 'Regisztráció')}
            </ModalHeader>

            <ModalContent /*className='max-h-screen overflow-y-auto'*/>

                {loggedIn && <div className='flex flex-col gap-2' >

                    <div className='text-xs dark:text-sm text-white'>Üdvözöljük a Terézváros.hu oldalán, jelenleg a következő felhasználói adatokkal van bejelentkezve: </div>

                    <div className='bg-black dark:bg-tv-yellow bg-opacity-10 rounded-lg shadow-xl text-white dark:text-black p-4 flex flex-col gap-2'>
                        <ProfileLine title='Felhasználónév' value={getUser().userName} icon='FaUser' />
                        <ProfileLine title='E-mail cím' value={getUser().email} icon='FaLock' />
                    </div>

                </div>}

                {(feedback && !loggedIn) && <>

                    {login ?
                        <div>Sikeres bejelentkezés!</div>
                        :
                        // <div className='text-xs'>Gratulálunk, sikeresen regisztrált a Terézváros.hu oldalára!<br /><br />Kérjük, ellenőrizze e-mail fiókját, és megerősítő e-mail-ben található aktiváló link segítségével <strong>aktiválja fiókját.</strong><br /><br />Az aktivációt követően a "Bejelentkezés" gombra kattintva tud belépni fiókjába.</div>
                        <div className='text-xs text-white'>Gratulálunk, sikeresen regisztrált a Terézváros.hu oldalára! <br /><br />A "Bejelentkezés" gombra kattintva tud belépni fiókjába.</div>
                    }

                </>}

                {(!feedback && !loggedIn) && <>

                    <div className='text-xs dark:text-sm text-white'>Amennyiben még nem regisztrált, az e-mail címe és irányítószáma megadásával regisztrálhat a szavazásra. A szavazáson terézvárosi állampolgár vehetnek részt! A bejelentkezéshez szükséges jelszót a megadott e-mail címre küldjük ki.</div>

                    {/* {!login && <input className={'generic-input ' + (userNameError ? 'generic-input-error' : '')}
                        type='text'
                        name='user-name'
                        placeholder={'Felhasználónév' + (login ? '' : '*') + ' ...'}
                        value={userName}
                        onChange={e => setUserName(e.target.value)} />} */}

                    <input className={'generic-input ' + (emailError ? 'generic-input-error' : '')}
                        type='email'
                        name='email'
                        placeholder={'E-mail cím' + (login ? '' : '*') + ' ...'}
                        value={email}
                        onChange={e => setEmail(e.target.value)} />

                    {/* {!login && <input className={'generic-input ' + (zipCodeError ? 'generic-input-error' : '')}
                        type='text'
                        name='zipCode'
                        placeholder={'Irányítószám' + (login ? '' : '*') + ' ...'}
                        value={zipCode}
                        onChange={e => setZipCode(e.target.value)} />} */}

                    {login && <input className={'generic-input ' + (passwordError ? 'generic-input-error' : '')}
                        type='password'
                        name='password'
                        placeholder={'Jelszó' + (login ? '' : '*') + ' ...'}
                        value={password}
                        onChange={e => setPassword(e.target.value)} />}

                    {/* {!login && <input className={'generic-input ' + (passwordAgainError ? 'generic-input-error' : '')}
                        type='password'
                        name='password-again'
                        placeholder={'Jelszó újra' + (login ? '' : '*') + ' ...'}
                        value={passwordAgain}
                        onChange={e => setPasswordAgain(e.target.value)} />} */}

                    {/* {!login && <input className={'generic-input ' + (fullNameError ? 'generic-input-error' : '')}
                        type='text'
                        name='fullName'
                        placeholder={'Teljes név' + ' ...'}
                        value={fullName}
                        onChange={e => setFullName(e.target.value)} />} */}

                    {/* {!login && <input className={'generic-input ' + (phoneNumberError ? 'generic-input-error' : '')}
                        type='text'
                        name='phoneNumber'
                        placeholder={'Telefonszám' + ' ...'}
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)} />} */}

                    {!login && <input className={'generic-input ' + (addressError ? 'generic-input-error' : '')}
                        type='text'
                        name='address'
                        placeholder={'Irányítószám' + ' ...'}
                        value={address}
                        onChange={e => setAddress(e.target.value)} />}

                    {addressError && <div className='bg-tv-red rounded-lg p-4 text-white text-xs'>{addressError}</div>}

                    {!login && <div className='border dark:border-0 bg-tv-purple-dark dark:bg-tv-yellow rounded-lg p-4 text-xs dark:text-sm flex flex-row gap-4 items-center justify-center text-white dark:text-black'>
                        <input type='checkbox' onChange={e => setTermsAccepted(e.target.checked)} />
                        <span>Az <a className='text-tv-red dark:text-black font-semibold dark:underline' href='https://terezvaros.hu/terezvaros-portal/methods/fmFrontendControl/getFile?key=WIr15YXWT7fe1C10BkMlceb8jfHi7oikVsJBu7J3HUvh5PqiaK' target='_blank' rel="noreferrer">Adatkezelési tájékoztatót</a> elolvastam, a benne foglaltakat megismertem, az adatkezelési hozzájárulást megadom.*</span>
                    </div>}

                </>}

                <div className='flex flex-col gap-2'>

                    {loggedIn && <ModalButton label={'Kijelentkezés'} onClick={() => doLogout()} />}

                    {!loggedIn && <>
                        <ModalButton disabled={!buttonEnabled} label={login ? 'Bejelentkezés' : 'Regisztráció'} onClick={() => login ? doLogin() : doRegistration()} />
                        <ModalButton secondary label={login ? 'Regisztráció' : 'Bejelentkezés'} onClick={() => setLogin(!login)} />
                    </>}

                </div>

            </ModalContent>

        </Modal >
    );
}

function ProfileLine(props) {
    return (
        <div className='flex flex-row items-center gap-2 text-sm'>
            <DynamicIcon className='' iconName={props.icon} />
            <div className='font-bold leading-none'>{props.title + ':'}</div>
            <div className='leading-none'>{props.value}</div>
        </div>
    );
}