import React from 'react';
import PropTypes from 'prop-types';

const YoutubeImage = (props) => (
  <div className={'bg-no-repeat bg-center bg-cover ' + (props.className || '')} style={{ backgroundImage: `url(https://i.ytimg.com/vi/${props.embedId}/maxresdefault.jpg)` }} />
);

YoutubeImage.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeImage;