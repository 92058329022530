import React, { useState, useEffect } from 'react';

import DynamicIcon from 'elements/DynamicIcon.js';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getClassNameByColor, getColorByIndex, getCompColorByColor } from 'utils/DesignUtils.js';

// import CmsFrontendControl from 'services/CmsFrontendControl.js';

// const listLength = 20;

export default function IdeaList(props) {
    const [ideas, setIdeas] = useState(null);
    const [keyList, setKeyList] = useState(null);
    // const [end, setEnd] = useState(false);

    useEffect(() => {
        setIdeas(props.data);
        setKeyList(createKeyList(props.data));
    }, [props.data])

    const createKeyList = (data) => {
        let list = [];
        for (let i = 0; i < data.length; i++)
            list.push(data[i].key);
        return list;
    }

    const getByKey = (key) => {
        for (let i = 0; i < ideas.length; i++)
            if (ideas[i].key === key)
                return ideas[i];
        return null;
    }

    const move = (direction, key) => {
        let arr = [];
        for (let i = 0; i < keyList.length; i++)
            arr.push(keyList[i]);

        const oldIndex = keyList.indexOf(key);
        arr.splice(oldIndex, 1);

        if (direction < 1)
            arr.splice(Math.max(oldIndex - 1, 0), 0, key);
        else
            arr.splice(Math.min(oldIndex + 1, arr.length), 0, key);

        setKeyList(arr);
    }

    return (
        <div className={'py-0 sm:py-10 flex flex-col items-center justify-center gap-8 ' + (props.className || '')}>

            <div className='flex flex-col gap-8 px-0 sm:px-5'>
                {ideas && keyList.map((key, index) => <IdeaItem
                    className={index % 2 === 0 ? 'md:mr-32' : 'md:ml-32'}
                    key={key}
                    data={getByKey(key)}
                    index={index}
                    onMoveUp={() => move(-1, key)}
                    onMoveDown={() => move(1, key)} />)}
            </div>

            {/* {!end && <button className='w-full flex flex-row items-center justify-center gap-2 bg-tv-purple hover:bg-tv-red shadow-xl rounded-xl py-6 text-white uppercase text-sm font-bold transition' onClick={() => { loadMore() }}>

                További ötletek betöltése
                <DynamicIcon className='text-lg opacity-50' iconName='FaChevronDown' />

            </button>} */}

        </div>
    );
}

function IdeaItem(props) {
    const getColor = () => { return getColorByIndex(props.index) };
    const getBgColor = () => { return getClassNameByColor(getColor(), 'bg') };
    const getCompBgColor = (color) => { return getClassNameByColor(getCompColorByColor(color), 'bg') };
    // const getCompHoverBgColor = (color) => { return 'hover:' + getClassNameByColor(getCompColorByColor(color), 'bg') };

    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        description: getValueFromParameterMap(props.data, 'description'),
        details: getValueFromParameterMap(props.data, 'details'),
    }

    return (
        <div className={'shadow-xl rounded-xl p-6 text-white flex flex-col md:flex-row gap-4 dark:bg-tv-yellow ' + getBgColor() + ' ' + (props.className || '')}>

            <div className='flex-1 flex flex-col gap-4 order-2 md:order-1'>

                <div className={'flex flex-row items-center justify-center bg-tv-purple ml-0 lg:-ml-12 shadow-xl rounded-lg lg:rounded-xl p-6 dark:bg-tv-purple  ' + getCompBgColor(getColor())}>

                    <div className='flex flex-col flex-1'>
                        {_data.title && <div className={'uppercase text-xl font-extrabold dark:text-white ' + (_data.title.length > 55 ? 'sm:text-3xl' : 'sm:text-4xl') + ' ' + (getColor() === 'red' ? 'text-tv-red' : 'text-tv-yellow')}>
                            {_data.title}
                        </div>}
                        {_data.ideaDescription && <div className={' ' + (getColor() === 'red' ? 'text-white' : 'text-white')}>{_data.ideaDescription}</div>}
                    </div>

                    <DynamicIcon className='text-5xl opacity-50' iconName='FaLightbulb' />

                </div>

                <div className='flex flex-col md:flex-row gap-4'>

                    <IdeaDescriptionBox title='Ötlet leírása' value={_data.description} />
                    <IdeaDescriptionBox title='A megvalósítás részletei' value={_data.details} />

                </div>

            </div>

        </div>
    );
}

function IdeaDescriptionBox(props) {
    return (
        <div className='flex-1 flex flex-col gap-3 rounded-xl bg-white bg-opacity-10 p-4'>
            <div className='text-sm dark:text-base uppercase text-tv-yellow dark:text-black font-bold tracking-wider' dangerouslySetInnerHTML={{ __html: props.title }} />
            <div className='text-sm dark:text-black dark:text-base' dangerouslySetInnerHTML={{ __html: props.value }} />
        </div>
    );
}