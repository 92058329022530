import React from 'react';

import useDarkMode from 'hooks/useDarkMode.js';

export default function DarkModeSwitch(props) {
    const [colorTheme, setTheme] = useDarkMode();

    return (
        <button
            className={'flex flex-row items-center justify-center ' + (props.className || '')}
            onClick={() => { setTheme(colorTheme) }}>

            <img className='w-5 h-5 bg-tv-gray-darkest rounded-full bg-opacity-10 p-0.5 ' alt='search' src='/assets/images/icons/accessibility.svg' />

            {props.label && <div className={'pl-2 pr-1 ' + (props.labelClassName || '')}>Akadálymentes változat</div>}

        </button>
    );
}