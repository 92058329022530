import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import MenuSlider from 'components/menuslider/MenuSlider.js';
import DynamicIcon from 'elements/DynamicIcon';
import ModalAuth from 'elements/modal/ModalAuth';
// import ModalAuth from 'elements/modal/ModalAuth';

import { EventRegister } from 'utils/EventRegister.js';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import AuthIndicator from 'elements/AuthIndicator';

// const menuItems = [
//     { title: 'Kezőoldal', path: '/', active: true },
//     { title: 'Javaslattétel', url: 'https://terezvaros.hu/kozossegi-koltsegvetes', active: true },
//     { title: 'Beérkezett javaslatok', active: false },
//     { title: 'Szavazás', active: false },
//     { title: 'Eredmények', active: false }
// ]

export default function Header(props) {
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [sliderOpen, setSliderOpen] = useState(false);
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        let userAuthNeededEvent = EventRegister.addEventListener('userAuthNeeded', (data) => { setAuthModalVisible(true) });
        return () => EventRegister.removeEventListener(userAuthNeededEvent)
    }, []);

    const handleScroll = (e) => {
        setSticky(window.scrollY > 0 ? true : false);
    };

    return (
        <header className={'w-full relative flex ' + (props.className || '')}>

            <img className='max-w-full w-[680px] xl:w-[880px] absolute top-0 right-0 dark:hidden' alt='artifact' src='assets/images/header-image.png' />

            <div className='block sm:hidden'>
                <MenuSlider className='flex sm:hidden' open={sliderOpen} onSliderClose={() => setSliderOpen(false)} menuItems={getValueFromParameterMap(getWebsite().globalContent, 'menu')} />
            </div>

            <div className='w-full flex items-center justify-center site-px'>

                <div className='w-full max-w-tv-boxed py-8 flex flex-row items-end z-10 mt-28 sm:mt-32 lg:mt-0'>

                    <div className='flex-1 flex flex-col gap-4 items-start'>

                        <Heading />
                        <Menu className='hidden sm:flex' />


                    </div>

                </div>

            </div>

            <MobileMenuButton className='fixed  z-40 flex sm:hidden' onClick={() => setSliderOpen(true)} sticky={sticky} />

            <img className='absolute hidden md:block right-20 lg:right-40 bottom-24 lg:-bottom-20 w-16 lg:w-20 h-16 lg:h-20 z-40 dark:hidden' alt='artifact' src='assets/images/icons/artifact-1.svg' />

            {authModalVisible && <ModalAuth onClose={() => { setAuthModalVisible(false); }} />}

        </header >
    );
}

function Menu(props) {
    return (
        <div className={'flex-row gap-3 h-12 ' + (props.className || '')}>

            <MenuMainButton className='bg-tv-red dark:bg-tv-purple hover:bg-tv-cyan' logoClassName='h-4' logo='assets/images/logo/shield-fill-white.svg' url='https://terezvaros.hu/' />

            <div className='bg-tv-purple dark:bg-tv-yellow shadow-xl rounded-xl flex flex-row items-center justify-center h-full'>

                {getValueFromParameterMap(getWebsite().globalContent, 'menu') ? getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList.map((item) => <MenuItem key={item.id} data={item} />) : null}

                <AuthIndicator
                    containerClassName='flex flex-row h-full'
                    loginLoggedInClassName='rounded-r-xl'
                    loginClassName='bg-tv-cyan dark:bg-tv-red hover:bg-tv-red rounded-xl text-white text-xs dark:text-sm uppercase font-bold tracking-wider px-2 xl:px-3 h-full shadow-xl whitespace-nowrap transition'
                    kauClassName='bg-tv-pink dark:bg-tv-purple hover:bg-tv-orange rounded-r-xl text-white text-xs dark:text-sm uppercase font-bold tracking-wider px-2 xl:px-3 h-full shadow-xl whitespace-nowrap transition'
                />
                {/* <a
                    href='https://terezvaros.dev.creo.hu/terezvaros-portal/methods/kauControl/auth'
                    className='bg-tv-pink hover:bg-tv-orange rounded-r-xl text-white text-xs uppercase font-bold tracking-wider px-2 xl:px-3 h-full shadow-xl whitespace-nowrap flex flex-row items-center justify-center transition' >
                    KAÜ Belépés
                </a> */}

            </div>

        </div>
    );
}

function MobileMenuButton(props) {
    return (
        <div className={'w-16 h-16 shadow-xl flex items-center justify-center transition-all ' + (props.sticky ? 'rounded-bl-xl bg-tv-red top-0 right-0 ' : 'rounded-xl bg-tv-purple top-4 right-4 ') + (props.className || '')} onClick={() => props.onClick()}>
            <DynamicIcon className='text-white text-2xl' iconName='FaBars' />
        </div>
    );
};

function MenuItem(props) {
    const labelClassName = 'text-white dark:text-black text-xs dark:text-sm uppercase font-bold tracking-wider first:pl-4 first:xl:pl-6 first:pr-4 last:xl:pr-6 px-2 xl:px-3 whitespace-nowrap transition';

    if (props.data.targetType !== 'None') {
        if (props.data.targetType !== 'ExternalUrl')
            return (<NavLink exact={true} to={props.data.path} activeClassName='text-tv-yellow dark:text-black' className={labelClassName + ' hover:scale-[1.1]'}>{props.data.title}</NavLink>);
        else
            return (<a href={props.data.value} className={labelClassName + ' hover:scale-[1.1]'}>{props.data.title}</a>);
    } else {
        return (<div className={labelClassName + ' opacity-30 cursor-pointer'}>{props.data.title}</div>);
    }
}

function MenuMainButton(props) {
    return (
        <a href={props.url} className={'h-full transition shadow-xl aspect-square rounded-xl flex items-center justify-center group ' + (props.className || '')}>
            <img className={'transition group-hover:scale-[1.2] ' + (props.logoClassName || '')} alt='logo' src={props.logo} />
        </a>
    );
}

function Heading(props) {
    return (
        <div className='flex flex-col text-tv-red dark:text-tv-yellow font-extrabold tracking-wide xl:tracking-wider'>
            <div className='text-7xl lg:text-6xl xl:text-7xl'>Terézvárosi</div>
            <div className='text-6xl lg:text-5xl xl:text-6xl'>Közösségi <span className='text-tv-purple hidden sm:inline-block'>Költségvetés</span></div>
            <div className='flex sm:hidden text-tv-purple font-extrabold text-5xl mt-1'>Költségvetés</div>
        </div>
    );
}