import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export default function Toast(props) {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (props.message)
            setMessage(props.message);
        const timer = setTimeout(() => { props.onHide(); }, 2000);
        return () => clearTimeout(timer);
    }, [props, props.message]);

    return (
        <motion.button
            onClick={props.onHide}
            className={'fixed bg-black dark:bg-tv-red bg-opacity-75 dark:bg-opacity-100 rounded-xl shadow-xl p-5 z-50 top-5 text-white ' + (props.className || '')}
            initial={props.message ? 'visible' : 'hidden'}
            animate={props.message ? 'visible' : 'hidden'}
            transition={{ duration: 0.15, type: 'spring', stiffness: 150 }}
            variants={{ visible: { translateY: 0, opacity: 1, display: 'flex' }, hidden: { translateY: -90, opacity: 0, transitionEnd: { display: 'none' } } }}
            onAnimationComplete={definition => { definition === 'hidden' ? setMessage(null) : console.log('') }}>

            <div className='text-sm md:text-base dark:md:text-lg font-semibold dark:font-bold tracking-wide'>{message}</div>

        </motion.button >
    )
}