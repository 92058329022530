import React, { useState } from 'react';

import IdeaList from 'components/idealist/IdeaList.js';
import Vote from 'components/vote/Vote.js';
import Result from 'components/result/Result.js';
import News from 'components/news/News.js';
import Videos from 'components/videos/Videos.js';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Simplepage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        ideaList: (page && getValueFromParameterMap(page, 'idea-list')) && getValueFromParameterMap(page, 'idea-list').contentList,
        videoList: (page && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'kk-video')) && getValueFromParameterMap(getValueFromParameterMap(page, 'content'), 'kk-video').contentList,
        newsList: (page && getValueFromParameterMap(page, 'newsList')) && getValueFromParameterMap(page, 'newsList'),
    }

    return (
        <NBossCMSPage className='flex flex-col items-center justify-center' pageprops={props} pageDataReady={page => { setPage(page) }}>

            {page && < div className='w-full max-w-tv-boxed flex flex-col gap-8'>

                {(_data.ideaList && page.pageTemplate.key === 'kk-microsite-ideas') && <IdeaList data={_data.ideaList} listKey={getValueFromParameterMap(page, 'idea-list').key} />}

                {(_data.ideaList && page.pageTemplate.key === 'kk-microsite-vote') && <Vote data={page} listKey={getValueFromParameterMap(page, 'idea-list').key} />}

                {(_data.ideaList && page.pageTemplate.key === 'kk-microsite-result') && <Result data={page} listKey={getValueFromParameterMap(page, 'idea-list').key} />}

                {_data.newsList && <News data={_data.newsList} title={'Közösségi Költségvetés \'22 megvalósult ötleteiről'} subTitle={'minden, amit a Közösségi Költségvetés \'22 megvalósult ötleteiről tudni kell'} year={2022} />}

                {_data.videoList && <Videos data={_data.videoList} />}

            </div>}

        </NBossCMSPage >
    );
}