import React, { useState, useEffect } from 'react';
import { EventRegister } from 'utils/EventRegister.js';

import DynamicIcon from 'elements/DynamicIcon.js';

import { getUser } from 'utils/UserUtils.js';

export default function AuthIndicator(props) {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        setLoggedIn(getUser() ? true : false)
        let userChangedEvent = EventRegister.addEventListener('userChanged', (data) => { setLoggedIn(data ? true : false); });
        return () => EventRegister.removeEventListener(userChangedEvent)
    }, []);

    return (
        <div className={'' + (props.containerClassName || '')}>

            <button className={'flex flex-row items-center ' + (props.loginClassName || '') + ((loggedIn && props.loginLoggedInClassName) ? (' ' + props.loginLoggedInClassName) : '')} onClick={() => EventRegister.emit('userAuthNeeded', null)}>
                {props.icon ? <DynamicIcon className='mr-3 mb-0.5' iconName='FaUser' /> : null}
                <span className=''>{loggedIn ? 'Kijelentkezés' : 'Bejelentkezés'}</span>
            </button>

            {/* {!loggedIn && <a href='https://terezvaros.dev.creo.hu/terezvaros-portal/methods/kauControl/auth' className={'flex flex-row items-center ' + (props.kauClassName || '')}>
                {props.icon ? <DynamicIcon className='mr-3 mb-0.5' iconName='FaUser' /> : null}
                KAÜ Belépés
            </a>} */}

        </div>
    )
}