import React, { useState, useEffect } from 'react';

import Toast from 'elements/Toast.js';
import DynamicIcon from 'elements/DynamicIcon';

import CmsVoteControl from 'services/CmsVoteControl.js';
import CmsFrontendControl from 'services/CmsFrontendControl.js';
import VisitorAccessManagement from 'services/VisitorAccessManagement.js';

import { EventRegister } from 'utils/EventRegister.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getClassNameByColor, getColorByIndex, getCompColorByColor } from 'utils/DesignUtils.js';
import { getUser, setUser } from 'utils/UserUtils.js';
import { shuffleArray } from 'utils/GenericUtils';
import ModalDialog from 'elements/modal/ModalDialog';

// import { setUser, getUser } from 'utils/UserUtils.js';

export default function Vote(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [finished, setFinished] = useState(false);
    const [finishModalVisible, setFinishModalVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(true);
    const [ideas, setIdeas] = useState(null);
    const [toast, setToast] = useState(null);

    const closed = true;

    useEffect(() => {
        loadList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, loggedIn]);

    useEffect(() => {
        setLoggedIn(getUser() ? true : false)
        setFinished((getUser() && getUser().voteClosed) ? true : false)
        let userChangedEvent = EventRegister.addEventListener('userChanged', (data) => {
            setLoggedIn(data ? true : false);
            setFinished(data ? data.voteClosed : false);
        });
        return () => EventRegister.removeEventListener(userChangedEvent)
    }, []);

    const loadList = () => {
        CmsFrontendControl.getList(props.data.parameterMap['idea-list'].value.key, 0, 0).then(result => {
            const list = ideas ? orderByShuffle(ideas, result.contentList) : shuffleArray(result.contentList)

            const _list = [];
            for (let i = 0; i < list.length; i++) { if (getValueFromParameterMap(list[i], 'year') === 2023) _list.push(list[i]); }

            setIdeas(_list);
        });
    }

    const orderByShuffle = (_old, _new) => {
        let order = [];
        for (let i = 0; i < _old.length; i++)
            order.push(_old[i].key);
        let __new = [];
        for (let i = 0; i < order.length; i++) {
            for (let j = 0; j < _new.length; j++) {
                if (order[i] === _new[j].key)
                    __new.push(_new[j]);
            }
        }
        return __new;
    }

    const handleVoteChanged = (vote, item) => {
        if (loggedIn) {
            CmsVoteControl.vote(item.dataMap.vote.typeKey, item.key, item.dataMap.vote.voteValue === vote ? 0 : vote).then(result => {
                loadList();
                // setToast(item.dataMap.vote.voteValue === vote ? 'Szavazat visszavonva' : 'Sikeres szavazat leadás')
            });
        } else {
            EventRegister.emit('userAuthNeeded', null)
        }
    };

    const finishVoting = () => {
        VisitorAccessManagement.closeVote().then(result => {
            VisitorAccessManagement.getLoggedInClient().then(result => {
                setUser(result);
                setFinishModalVisible(false);
            });
        });
    };

    return (
        <div className={'py-0 sm:py-10 flex flex-col items-center justify-center gap-12 ' + (props.className || '')}>

            <TermsBox
                onTermsChanged={(status) => setTermsAccepted(status)}
                ideaCount={ideas && ideas.length}
                hideTerms={closed}
                title='Közösségi Költségvetés - Szavazás 2023'
                content={'Az oldalon található ' + ((ideas && ideas.length) ? ideas.length : '-') + ' ötletre regisztráció és bejelentkezés, valamint az Adatkezelési Tájékoztató és Hozzájárulás elfogadása után adható le szavazat. Mind a ' + ((ideas && ideas.length) ? ideas.length : ' - ') + ' ötlet nem látható egyszerre, görgetéssel érhetőek el a további ötletek. A szavazás a csillagok bejelölésével történik, a csillagok száma jelzi, hogy mennyire támogatja a javaslatot 1-től 5-ig terjedő skálán, ahol az ötös a maximum adható pontszám. Mindegyik ötletre adhat le tetszőleges számú csillagot, de nem kötelező mindegyik projektre szavazni. A szavazás befejezése után kattintson a lap alján a "Szavazatok beküldése" gombra. Amennyiben többet szeretne megtudni az egyes javaslatokról, a megvalósítás részleteiről és a költségekről, a "Szavazásra kerülő javaslatok" menüpontban talál bővebb információkat. Közreműködését köszönjük!'}
            />

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-0 sm:px-5 gap-12'>
                {ideas && ideas.map((item, index) => <IdeaItem
                    key={index}
                    data={item}
                    index={index}
                    vote={item.dataMap.vote.voteValue}
                    loggedIn={loggedIn}
                    finished={finished}
                    closed={closed}
                    termsAccepted={termsAccepted}
                    onVoteChanged={(vote) => handleVoteChanged(vote, item)} />)}
            </div>

            {(loggedIn && ideas && termsAccepted && !finished) && <button onClick={() => setFinishModalVisible(true)} className='w-full py-5 bg-tv-purple hover:bg-tv-red transition text-tv-yellow hover:text-white font-extrabold tracking-wider shadow-xl uppercase rounded-xl'>Szavazatok beküldése</button>}

            <Toast message={toast} onHide={() => setToast(null)} />

            {finishModalVisible && <ModalDialog
                title='Figyelem!'
                content='A szavazatok beküldése után nem módosíthatja a szavazatokat. Valóban be szeretné küldeni szavazatait?'
                primaryButtonLabel='Beküldés'
                onPrimaryButtonClick={() => finishVoting()}
                onClose={() => setFinishModalVisible(false)} />}

        </div>
    );
}

export function TermsBox(props) {
    const [dataManagemenInfo, setDataManagementInfo] = useState(false);
    const [dataManagemenConsent, setDataManagemenConsent] = useState(false);

    useEffect(() => {
        if (props.onTermsChanged)
            props.onTermsChanged((dataManagemenInfo && dataManagemenConsent) ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataManagemenInfo, dataManagemenConsent]);

    return (
        <div className='w-full p-4 bg-tv-cyan dark:bg-tv-yellow rounded-xl shadow-xl flex flex-col md:flex-row gap-4 mx-8'>

            <div className='flex-1 flex flex-col gap-4'>
                <div className='bg-tv-purple  text-tv-yellow dark:text-white rounded-xl shadow-xl p-4 ml-0 xl:-ml-12 flex flex-row gap-4 items-center'>
                    <DynamicIcon className='text-white text-3xl opacity-50' iconName='FaInfoCircle' />
                    <div className='font-extrabold uppercase text-2xl '>{props.title}</div>
                    {/* <div className='font-extrabold uppercase text-2xl' dangerouslySetInnerHTML={{ __html: props.title }} /> */}


                </div>
                <div className='flex-1 bg-black bg-opacity-10 shadow-lg rounded-lg p-4 text-white dark:text-black text-xs dark:text-base' dangerouslySetInnerHTML={{ __html: props.content }} />
                {/* <div className='flex-1 bg-black bg-opacity-10 shadow-lg rounded-lg p-4 text-white dark:text-black text-xs dark:text-base'>
                    Az oldalon található {props.ideaCount ? props.ideaCount : '-'} ötletre regisztráció és bejelentkezés, valamint az Adatkezelési Tájékoztató és Hozzájárulás elfogadása után adható le szavazat. Mind a {props.ideaCount ? props.ideaCount : '-'} ötlet nem látható egyszerre, görgetéssel érhetőek el a további ötletek. A szavazás a csillagok bejelölésével történik, a csillagok száma jelzi, hogy mennyire támogatja a javaslatot 1-től 5-ig terjedő skálán, ahol az ötös a maximum adható pontszám. Mindegyik ötletre adhat le tetszőleges számú csillagot, de nem kötelező mindegyik projektre szavazni. A szavazás befejezése után kattintson a lap alján a "Szavazatok beküldése" gombra. Amennyiben többet szeretne megtudni az egyes javaslatokról, a megvalósítás részleteiről és a költségekről, a "Szavazásra kerülő javaslatok" menüpontban talál bővebb információkat. Közreműködését köszönjük!
                    {props.content}
                </div> */}
            </div>

            {!props.hideTerms && <div className='flex-1 p-4 bg-tv-red dark:bg-tv-purple rounded-xl shadow-xl flex flex-col gap-4'>
                <TermsItem value={dataManagemenInfo} onChange={(status) => setDataManagementInfo(status)} label='Az Adatkezelési Tájékoztató tartalmát megismertem.' buttonLabel='Adatkezelési Tájékoztató' buttonLink='https://terezvaros.hu/terezvaros-portal/methods/fmFrontendControl/getFile?key=WIr15YXWT7fe1C10BkMlceb8jfHi7oikVsJBu7J3HUvh5PqiaK' />
                <TermsItem value={dataManagemenConsent} onChange={(status) => setDataManagemenConsent(status)} label='Az Adatkezelési Hozzájárulást megadom.' buttonLabel='Adatkezelési Hozzájárulás' buttonLink='https://terezvaros.hu/terezvaros-portal/methods/fmFrontendControl/getFile?key=c0tGLvF034tYIInTGZHpPUJpjZQFl2e2tK3fWD7ynOQYj5SNZ2' />
            </div>}

        </div>
    );
}

function TermsItem(props) {
    return (
        <div className='bg-black bg-opacity-10 dark:bg-opacity-100 shadow-lg rounded-lg flex-1 p-4 flex flex-col sm:flex-row sm:items-center gap-4'>

            <div className='flex-1 flex flex-row items-center gap-3'>
                <input className='h-4 w-4' type='checkbox' value={props.value} onChange={e => props.onChange(e.target.checked)} />
                <div className='text-white text-xs dark:text-base lg:text-sm font-semibold flex-1'> {props.label}</div>
            </div>

            <a href={props.buttonLink} target='_blank' className='w-full sm:w-auto bg-tv-purple hover:bg-tv-yellow transition rounded py-3 px-4 text-white text-xs dark:text-base font-semibold' rel="noreferrer">
                {props.buttonLabel}
            </a>

        </div>
    );
}

export function IdeaItem(props) {
    const getColor = () => { return getColorByIndex(props.index) };
    const getBgColor = () => { return getClassNameByColor(getColor(), 'bg') };
    const getCompBgColor = (color) => { return getClassNameByColor(getCompColorByColor(color), 'bg') };

    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        description: getValueFromParameterMap(props.data, 'description'),
        details: getValueFromParameterMap(props.data, 'details'),
    }

    return (
        <div className={'shadow-xl rounded-xl p-4 text-white dark:text-black flex flex-col gap-4 dark:bg-tv-yellow ' + getBgColor() + ' ' + (props.className || '')}>

            <VoteBox display={props.display} voteSum={props.voteSum} vote={props.vote} onVoteChanged={props.onVoteChanged} loggedIn={props.loggedIn} termsAccepted={props.termsAccepted} finished={props.finished} closed={props.closed} />

            <div className={'flex flex-row items-center justify-center bg-tv-purple ml-0 lg:-ml-12 shadow-xl rounded-lg lg:rounded-xl p-4 dark:bg-tv-purple ' + getCompBgColor(getColor())}>

                <div className='flex flex-col flex-1'>
                    {_data.title && <div className={'uppercase text-base dark:text-lg dark:font-bold font-extrabold dark:text-white ' + (getColor() === 'red' ? 'text-tv-red' : 'text-tv-yellow')}>
                        {_data.title}
                    </div>}
                </div>

            </div>

            {_data.description && <div className={'flex-1 text-xs dark:text-sm rounded-xl bg-white bg-opacity-10 p-3 mb-0'} dangerouslySetInnerHTML={{ __html: _data.description }}></div>}

        </div>
    );
}

function VoteBox(props) {
    const [hover, setHover] = useState(null);

    return (
        <div className='rounded-lg bg-black bg-opacity-10 dark:bg-opacity-100 dark:text-white shadow-lg flex flex-row p-3' onMouseLeave={() => setHover(null)}>

            {(props.loggedIn && props.termsAccepted && !props.finished && !props.closed) && Array(5).fill(null).map((itm, index) => <VoteItem
                hover={hover !== null && index + 1 <= hover}
                voted={props.vote !== null && index + 1 <= props.vote}
                key={index}
                index={index + 1}
                onMouseEnter={() => setHover(index + 1)}
                onClick={() => props.onVoteChanged(index + 1)} />)}


            {props.voteSum && <div className='text-xs dark:text-sm text-center w-full'>Elért pontszám: <span className='font-bold text-sm'>{props.voteSum}</span></div>}

            {!props.display && (props.closed ? <div className='text-xs dark:text-sm text-center w-full'>A szavazás lezárult.</div> : <>

                {!props.loggedIn && <div className='text-xs dark:text-sm text-center w-full'>A szavazáshoz bejelentkezés szükséges</div>}
                {(props.loggedIn && !props.termsAccepted) && <div className='text-xs dark:text-sm text-center w-full'>A szavazáshoz az Adatkezelési feltételek elfogadása szükséges</div>}
                {(props.loggedIn && props.termsAccepted && props.finished) && <div className='text-xs dark:text-sm text-center w-full'>A szavazatát sikeresen leadta</div>}

            </>)}

            {/* {<div className='text-xs dark:text-sm text-center w-full'>A szavazás lezárult.</div>} */}

        </div>
    );
}

function VoteItem(props) {
    return (
        <button className='relative group flex-1 aspect-square mr-3 last:mr-0' onMouseEnter={props.onMouseEnter} onClick={props.onClick}>

            <img className={'w-full h-full transition ' + (props.hover ? (props.voted ? 'opacity-100' : 'opacity-70') : (props.voted ? 'opacity-90' : 'opacity-40'))} alt='star' src={props.voted ? 'assets/images/star-color.png' : 'assets/images/star-white.png'} />
            <div className='w-full h-full absolute top-0 left-0 text-xxs flex items-center justify-center leading-none text-center font-extrabold text-white mt-0.5'>{props.index}</div>

        </button>
    )
}