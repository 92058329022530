export const getClassNameByColor = (color, type) => {
    const colorMap = {
        'red': {
            'bg': 'bg-tv-red'
        },
        'yellow': {
            'bg': 'bg-tv-yellow'
        },
        'purple': {
            'bg': 'bg-tv-purple'
        },
        'cyan': {
            'bg': 'bg-tv-cyan'
        },
        'green': {
            'bg': 'bg-tv-green'
        },
        'orange': {
            'bg': 'bg-tv-orange'
        },
        'pink': {
            'bg': 'bg-tv-pink'
        }
    }
    return colorMap[color][type];
};

export const getCompColorByColor = (color) => {
    const colorMap = {
        'red': 'yellow',
        'yellow': 'red',
        'purple': 'red',
        'cyan': 'purple',
        'green': 'orange',
        'orange': 'green',
        'pink': 'cyan'
    }
    return colorMap[color];
};

export const getColorByIndex = (index) => {
    const list = ['cyan', 'red', 'purple', 'orange', 'pink', 'green'];
    return list[index % list.length];
};