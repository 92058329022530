import React from 'react';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getClassNameByColor } from 'utils/DesignUtils.js';

const gapClassName = 'gap-6';

export default function Steps(props) {
    return (
        <div className={'p-0 sm:p-10 ' + (props.className || '')}>

            {props.data && <>

                <div className={'flex-1 flex flex-col ' + gapClassName}>
                    {props.data.map((item, index) => <Step key={index} index={index} data={item} last={index === props.data.length - 1} />)}
                </div>

            </>}

        </div >
    );
}

function Step(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        subTitle: getValueFromParameterMap(props.data, 'sub-title'),
        content: getValueFromParameterMap(props.data, 'content'),
        color: getValueFromParameterMap(props.data, 'color'),
    }

    const getArrow = (color) => { return 'assets/images/icons/arrow-' + color + '.svg' }
    // const getBackArrow = (color) => { return 'assets/images/icons/arrow-back-' + color + '.svg' }

    return (
        <div className={'relative flex flex-col gap-4 w-full md:w-auto md:max-w-[80%] even:self-end odd:self-start items-center even:md:items-start odd:md:items-end'} >

            <div className={'flex flex-col gap-4 p-6 rounded-xl shadow-xl bg-opacity-95 w-full dark:bg-tv-yellow ' + (getClassNameByColor(_data.color, 'bg') || '')}>

                <div className='flex flex-col'>
                    {_data.title && <div className='uppercase text-xl dark:text-3xl text-tv-yellow dark:text-black font-bold dark:font-extrabold '>{_data.title}</div>}
                    {_data.subTitle && <div className='text-tv-yellow dark:text-black'>{_data.subTitle}</div>}
                </div>

                {_data.content && <div className='text-white dark:text-black text-sm dark:text-base ' dangerouslySetInnerHTML={{ __html: _data.content }} />}

            </div>

            {!props.last && <img className={'w-8 h-8 mx-72'} alt='arrow' src={getArrow(_data.color)} />}

        </div >
    );
}