import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/header/Header.js';
import Footer from 'components/footer/Footer.js';
import MainPage from 'pages/MainPage.js';
import SimplePage from 'pages/SimplePage.js';
import Error404Page from 'pages/Error404Page.js';

import CookieConsent from 'elements/CookieConsent';
import Toast from 'elements/Toast.js';

import CmsFrontendControl from 'services/CmsFrontendControl.js';
import KauControl from 'services/KauControl.js';
import VisitorAccessManagement from 'services/VisitorAccessManagement.js';
import { getWebsite, setWebsite } from 'utils/WebsiteUtils.js';
import { setUser } from 'utils/UserUtils.js';
import { getQueryVariable } from 'utils/GenericUtils.js';

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    const pageDictionary = {
        'kk-microsite': { page: MainPage },
        'kk-microsite-ideas': { page: SimplePage },
        'kk-microsite-vote': { page: SimplePage },
        'kk-microsite-result': { page: SimplePage },
        'content-layout': { page: SimplePage, templateProps: { menu: false } },
        '404': { page: Error404Page },
    };

    const createRoutes = (website) => {
        let _routes = [];

        if (website.mainPage)
            _routes.push({ path: '/', component: pageDictionary[website.mainPage.pageTemplate].page, pageKey: website.mainPage.pageKey });

        for (let key in website.sitemap)
            if (website.sitemap[key].pageTemplate && pageDictionary[website.sitemap[key].pageTemplate])
                _routes.push({ path: key, component: pageDictionary[website.sitemap[key].pageTemplate].page, pageKey: website.sitemap[key].pageKey, templateProps: pageDictionary[website.sitemap[key].pageTemplate].templateProps });
        // else
        //     console.log('[Missing template] ' + (website.sitemap[key].pageTemplate ? website.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);

        _routes.push({ path: '*', component: pageDictionary['404'].page, pageKey: 'egyszeru-oldal' });

        return _routes;
    }

    useEffect(() => {
        VisitorAccessManagement.getLoggedInClient().then(result => { setUser(result); });
        CmsFrontendControl.getWebsite(props.domainName, window.location.pathname).then(result => { _setWebsite(result) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     const cookieConsent = localStorage.getItem('tvCookieConsent')
    //     let showPopup = cookieConsent ? false : true;
    //     if (showPopup)
    //         setTimeout(() => setCookieConsentModalVisible(true), 1500);
    // }, [])

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    if (website && routes) {
        return (
            <Router>
                <div className='min-h-screen flex flex-col justify-between bg-no-repeat bg-cover bg-top bg-fixed dark:bg-black dark:bg-[length:0px_0px] ' style={{ backgroundImage: 'url(assets/images/pattern-yellow.png)' }} >

                    <div className='flex-1 flex flex-col'>
                        <Header className='z-20 ' />
                        <div className='site-px'>
                            <Switch>{routes.map((route, index) => (<Route key={index} exact path={route.path} render={props => (<route.component {...props} pageKey={route.pageKey} templateProps={route.templateProps || {}} />)} />))}</Switch>
                        </div>
                    </div>

                    <Footer className='z-10 mt-16' />

                    <CookieConsent className='z-50' />

                </div>
            </Router>
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center site-px gap-10 bg-no-repeat bg-cover bg-top bg-fixed dark:bg-black dark:bg-[length:0px_0px] ' style={{ backgroundImage: 'url(assets/images/pattern-yellow.png)' }}>
                <div className='flex flex-col text-white dark:text-tv-yellow opacity-50 dark:opacity-100 font-extrabold tracking-wide xl:tracking-wider items-center justify-center'>
                    <div className='text-6xl xl:text-7xl'>Terézvárosi</div>
                    <div className='text-3xl xl:text-4xl'>Közösségi Költségvetés</div>
                    {/* <div className='font-extrabold text-6xl mt-2'>Közösségi Költségvetés</div> */}
                </div>
                <p className='animate-pulse text-xl font-extrabold text-tv-orange dark:text-tv-yellow tracking-wider uppercase'>Betöltés...</p>
            </div>
        );
    }
}

export function NBossCMSPage(props) {
    const [toast, setToast] = useState(null);

    let location = useLocation();

    useEffect(() => {
        const message = getQueryVariable(location.search, 'kauMessage');
        if (message)
            KauControl.getLastMessage().then(result => { setToast(result); });

    }, [location]);

    useEffect(() => {
        if (checkPageLanguage(getWebsite().sitemap, window.location.pathname, getWebsite().currentLocale.key))
            CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        else
            // console.log('reload');
            window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkPageLanguage = (sitemap, path, localeKey) => {
        const hasSitemapItem = sitemap[path] ? true : false;
        // console.log('hasSitemapItem: ' + hasSitemapItem);
        const hasLocale = hasSitemapItem && (sitemap[path] && sitemap[path].localeList.indexOf(localeKey) > -1);
        // console.log('hasLocale: ' + hasLocale);
        const isMainPage = path === '/' || path === '';
        // console.log('isMainPage: ' + isMainPage);

        return (hasLocale || isMainPage || !hasSitemapItem) ? true : false;
    }

    return (<motion.div
        className={props.className}
        // style={{ originX: 0.5, originY: 0, transformPerspective: 2000 }}
        transition={{ duration: .3 }}
        initial={{ opacity: 0/*, rotateX: 0*/ }}
        animate={{ opacity: 1/*, rotateX: 0*/ }}
        exit={{ opacity: 1/*, rotateX: 0*/ }}>
        {props.children}
        <Toast message={toast} onHide={() => setToast(null)} />
    </motion.div>);
}

export const getParameterEndMatch = (data, valueKey, end) => {
    if (data && valueKey && end)
        for (let key in data.parameterMap)
            if (key.endsWith(end))
                return data.parameterMap[key][valueKey];
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
};

export const getNBossEndpoint = () => {
    return `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? `:${process.env.REACT_APP_API_PORT}` : ``)}/${process.env.REACT_APP_API_NAME}/methods/`;
}